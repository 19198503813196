<template>     
<tbody class="table-font" >
    <tr >
        <td>{{ index+1 }}</td>
        <td>{{item.customer.generatedid}}</td>
        <td class="visible-lg"><a href="#" @click="detail(item)">{{item.customer.name}}</a></td>
        <td v-if="isprint==0">
            <button type="button" class="btn btn-prime btn-xs"><i class="fa fa-edit" @click="downline(item)" >Downline</i></button>
        </td>
        <td><display-mobiles :mobiles="item.customer.mobiles"></display-mobiles></td> 
        <td :style= "isprint==1?'width:35%':''"><display-first-address :addresses="item.customer.addressess"></display-first-address></td>
        <td>{{moment(item.customer.advisordateofactive).format('DD-MM-YYYY')}}</td> 
        <td>
            <span v-if="item.customer.advisorcreator!=null">{{item.customer.advisorcreator.name}}</span>
        </td>
        <td @dblclick="showcommission(item)" @keyup.enter="showcommission(item)" style="color:green">
            <input v-if="commissionfield" type="text" :placeholder="item.customer.commission" v-model="item.customer.commission">
            <span>{{ item.customer.commission }}</span>
        </td> 
        
        <td>{{item.balance}}</td>
        <td v-if="isprint==0">
            <button type="button" class="badge bg-sheme btn-xs" ><i class='bx bxs-book-alt' @click="commissiondetails(item)" >View Details</i></button>
        </td>
        <td style="padding:0;text-align:center" v-if="isprint==0">
            <button class="btn btnsearch btn-view" type="button" @click="payemi()" style="background-color:#179b17!important;padding:7px;font-size:9px;border-radius:25px;">
                <i v-if="showpaymentform==false" class="fa fa-arrow-up"></i>
                <i v-else class="fa fa-arrow-down"></i> Pay
            </button>
        </td>
        <td >
            <button class="btn btn-xs b-r"  @click="Deactive(item)">
                <span style="background-color:red;color:white;padding: 5px;" v-if="item.customer.active==0">Deactive</span>
                <span style="background-color:green;color:white;padding: 5px;" v-else>Active</span>
            </button>
        </td>
    </tr>
    <tr v-if="showpaymentform">
        <td colspan="12">
            <advisor-payment-form :itemid="item.customer" newpayment="1"></advisor-payment-form>
        </td>
    </tr>
    <div v-if="page1(index)" class="html2pdf__page-break" style="margin-bottom:20px;"></div>
</tbody>
</template>
<script>
import moment from 'moment'
import DisplayMobiles from '../../components/mobile/DisplayMobiles.vue'
import DisplayFirstAddress from '../../components/address/DisplayFirstAddress.vue'
// import { mapGetters } from 'vuex'
import AdvisorPaymentForm from './AdvisorPaymentForm.vue'
export default {
    components:{DisplayMobiles,AdvisorPaymentForm,DisplayFirstAddress},
    props:['item','index','currentpage','noofrec','isprint'],
    data() {
        return {
            showpaymentform:false,
            commissionfield:false,
            moment:moment
        }
    },
    // mounted() {
    //     this.refresh();
    // },
    methods: {
        page1(index){
            if(index==8){
                return true
            }
            if((index-8)%9==0){
                return true
            }
            return false
        },
        Deactive(item){
            if(confirm("Do you Really Want To DeActive")){
                let parm=[]
                if(item.customer.active==1){
                    parm = {column:'active',id:item.customer.id,value:0};
                }else{
                    parm = {column:'active',id:item.customer.id,value:1};
                }
                this.$http.post('api/user/updatefield',parm)
                .then((response) => {
                    this.$store.commit('updateappendcustomers',response.data);
                    this.clickadvice=false
                    this.$parent.refresh();
                })
                .catch((err) => {
                    console.log(err)
                });
            }
        },
        refresh(){
            this.$store.dispatch('fetchadvisors','fetchcustomers')
        },
        downline(item){
            this.$router.push({path:"/downline/"+item.customer.id})
        },
        commissiondetails(item){
            this.$store.commit('assignviewno',4)
            this.$store.commit("assignadvisorid",item)
            let param = {id:item.customer.id}
            // this.$http.post('api/fetch/commission',param)
            // .then((response) => this.processDetailResponse(response.data))
            // .catch((err) => {
            //     console.log('', err)
            // });
            this.$http.post('api/fetch/commission/totalcount',param)
            .then((response) => this.processDetailResponseCount(response.data))
            .catch((err) => {
                console.log(err)
            });
           
        },
        
        // processDetailResponse(data){
        //     this.$store.commit('assigncommissiondetails',data)
        //     // this.$store.commit('assignviewno',4)
            
        // },
        processDetailResponseCount(data){
            this.$store.commit('assigndownlinecount',data)
        },
        
        payemi(){
            this.showpaymentform = !this.showpaymentform
            //this.$store.commit('assignedititem2',this.item)
        },
        showcommission(item){
            this.commissionfield=!this.commissionfield
            let param={id:item.customer.id,column:"commission",value:item.customer.commission}
            this.$http.post('api/user/updatefield',param)
            .then((response) => this.processUpdateResponse(response.data))
            .catch((err) => {
                console.log(err)
            });
        },
        detail(item){
            this.$store.commit('assignedititem',item)
            this.$store.commit('assignviewno',5)
        },
       
    },
}
</script>